import React from "react";

export default function Card({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div
      style={{
        background: "#ffffff",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
        borderRadius: "8px",
      }}
    >
      {children}
    </div>
  );
}
