/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";

interface UserInterface {
  username: string;
  email: string;
}

export function useToken(): UserInterface | void {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: UserInterface & {
        exp: number;
      } = jwt_decode(token);
      if (decoded && decoded.exp) {
        if (decoded.exp * 1000 < new Date().valueOf()) {
          localStorage.removeItem("token");
          return;
        } else {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          return {
            username: decoded.username,
            email: decoded.email,
          };
        }
      }
    }
  } catch (err) {
    console.log(err);
    return;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useCateAdmin(profile: any): { admin: boolean; cate: any[] } {
  const [admin, setAdmin] = useState(false);
  const [cate, setCate] = useState([]);
  useEffect(() => {
    setAdmin(
      !!(profile || []).roles?.find?.(
        (item: { role: string }) => item.role === "admin"
      )
    );
    axios.get("categories").then((res) => {
      const { data } = res;
      setCate(
        (profile || []).roles?.map((role: { domain: { category: string } }) => {
          const { name, id } = data.find(
            (c: { id: string }) => c.id === role.domain.category
          );
          return { text: name, value: id };
        })
      );
    });
  }, [profile]);
  return { admin, cate };
}
