import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import {
  Button,
  Table,
  Pagination,
  Modal,
  Dropdown as DropdownDefault,
} from "semantic-ui-react";
import { Formik, Form } from "formik";
import { Input, Dropdown } from "components/Form";
import TableInfo from "components/TableInfo";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { cateSelector } from "store/cateSlice";
import Card from "components/Card";

const DEFAULT_PAGE_SIZE = 10;

export default function Posts(): JSX.Element {
  const currentCate = useSelector(cateSelector);

  const [tagOptions, setTagOptions] = useState<
    { key: string; text: string; value: string }[]
  >([]);

  const [search, setSearch] = useState<{
    title: string;
    author: string;
    category: string;
    tag: string;
    page: number;
    page_size: number;
  }>({
    title: "",
    author: "",
    tag: "",
    category: currentCate?.value ?? "",
    page: 1,
    page_size: DEFAULT_PAGE_SIZE,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [changePostId, setChangePostId] = useState("");
  const [changeTagId, setChangeTagId] = useState("");

  const { data: tags } = useQuery<{
    tagDocuments: { name: string; _id: string }[];
  }>(
    ["tags", currentCate],
    async () => {
      const { data } = await axios.get(`categories/${currentCate?.value}`);
      return data;
    },
    {
      enabled: !!currentCate,
    }
  );

  const { isLoading, data, refetch } = useQuery(["posts", search], async () => {
    const { data } = await axios.get("search/posts", {
      params: { ...search },
    });
    return data;
  });

  const pinMutation = useMutation(
    ({ postId, pin }: { postId: string; pin: boolean }) =>
      axios.post(`/posts/${postId}/categorypin`, { pin }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const tagMutation = useMutation(
    ({ postId, tagId }: { postId: string; tagId: string }) =>
      axios.post(`/posts/${postId}/tagmove`, {
        tagId,
      }),
    {
      onSuccess: () => {
        refetch();
        setModalOpen(false);
      },
    }
  );

  const getTagName = useCallback(
    (tagId) => {
      if (tags && tags.tagDocuments) {
        return tags.tagDocuments.find((item) => item._id === tagId)?.name;
      }
    },
    [tags]
  );

  useEffect(() => {
    if (tags) {
      setTagOptions(
        tags?.tagDocuments?.map((item) => ({
          key: item._id,
          text: item.name,
          value: item._id,
        }))
      );
    }
  }, [tags]);

  useEffect(() => {
    if (currentCate) {
      setSearch({ ...search, category: currentCate.value });
    }
  }, [currentCate]);

  const page = data?.page;
  const total = Math.ceil(data?.total / data?.pageSize);

  return (
    <section className="postsWrapper">
      <Card>
        <Formik
          initialValues={{
            title: "",
            author: "",
            category: currentCate?.value as string,
            tag: "",
          }}
          onSubmit={(values) => {
            setSearch({ ...values, page: 1, page_size: DEFAULT_PAGE_SIZE });
          }}
        >
          <Form className="searchWrapper">
            <label>Title</label>
            <Input name="title" />
            <label>Author</label>
            <Input name="author" />
            <label>Tag</label>
            <Dropdown id="tag" name="tag" options={tagOptions} clearable />
            <Button type="submit" secondary>
              Search
            </Button>
            {/* <Button
              basic
              onClick={(e) => {
                e.preventDefault();
                setSearch({
                  ...search,
                  title: "",
                  author: "",
                  tag: "",
                  page: 1,
                });
              }}
            >
              Reset
            </Button> */}
          </Form>
        </Formik>
      </Card>
      <Card>
        {isLoading ? (
          <TableInfo content="Loading..." />
        ) : !data || !data.items || data.items.length === 0 ? (
          <TableInfo content="No data" />
        ) : (
          <>
            <Table basic unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Author</Table.HeaderCell>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell>Tag</Table.HeaderCell>
                  <Table.HeaderCell>LastDate</Table.HeaderCell>
                  <Table.HeaderCell>Comments</Table.HeaderCell>
                  <Table.HeaderCell>Bounty</Table.HeaderCell>
                  <Table.HeaderCell>PinToTop</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.items.map(
                  (
                    item: {
                      author: { username: string };
                      title: string;
                      tagName: { name: string; _id: string };
                      updatedAt: string;
                      commentsCount: number;
                      bounty: { amount: string; symbol: string };
                      categoryPinToTop: boolean | null;
                      id: string;
                      tag: string;
                    },
                    index: number
                  ) => (
                    <Table.Row key={index}>
                      <Table.Cell>{item.author.username}</Table.Cell>
                      <Table.Cell>{item.title}</Table.Cell>
                      <Table.Cell>{getTagName(item.tag)}</Table.Cell>
                      <Table.Cell>
                        {dayjs(item.updatedAt).format("YYYY/MM/DD HH:mm")}
                      </Table.Cell>
                      <Table.Cell>{item.commentsCount}</Table.Cell>
                      <Table.Cell>{`${
                        item.bounty
                          ? `${item.bounty?.amount} ${item.bounty?.symbol}`
                          : ""
                      }`}</Table.Cell>
                      <Table.Cell>
                        <Button
                          className="textButton"
                          onClick={() => {
                            pinMutation.mutate({
                              postId: item.id,
                              pin: !item.categoryPinToTop,
                            });
                          }}
                        >
                          {item.categoryPinToTop ? "Unpin" : "Pin"}
                        </Button>
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          className="textButton"
                          onClick={() => {
                            setChangePostId(item.id);
                            setChangeTagId(item.tag);
                            setModalOpen(true);
                          }}
                        >
                          Edit
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
            <div className="paginationWrapper">
              <div>共计{data?.total ?? 0}条记录</div>
              <Pagination
                activePage={page}
                totalPages={total}
                onPageChange={(e, { activePage }) =>
                  setSearch({ ...search, page: Number(activePage) })
                }
              />
            </div>
          </>
        )}
      </Card>
      <Modal size="mini" open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>Tag</Modal.Header>
        <Modal.Content>
          <DropdownDefault
            selection
            options={tagOptions}
            value={changeTagId}
            onChange={(e, { value }) => {
              setChangeTagId(value as string);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button
            secondary
            onClick={() =>
              tagMutation.mutate({
                postId: changePostId,
                tagId: changeTagId,
              })
            }
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    </section>
  );
}
