import React, { useEffect } from "react";
import { Button, Grid, Header, Image, Segment } from "semantic-ui-react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input } from "../Form";
import { login } from "service/api";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { userSelector, setUser } from "store/userSlice";
import { useToken } from "hooks";
import { useHistory } from "react-router";

export default function Login(): JSX.Element {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const token = useToken();

  useEffect(() => {
    if (user) {
      history.push("/");
    } else if (token) {
      dispatch(setUser(token));
    }
  }, [user]);

  if (token) return <div />;

  return (
    <Grid textAlign="center" style={{ height: "100vh" }}>
      <Grid.Column style={{ maxWidth: 350, marginTop: "80px" }}>
        <Segment>
          <Header as="h2" textAlign="center">
            <Image src="/logo.svg" size="medium" /> Coinask Admin
          </Header>
          <Formik
            initialValues={{ usernameOrEmail: "", password: "" }}
            validationSchema={Yup.object({
              usernameOrEmail: Yup.string().required("Required"),
              password: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { setErrors }) => {
              const res = await login(values);
              if (res.error) {
                if (res.error.data?.username) {
                  res.error.data.usernameOrEmail = res.error.data?.username;
                }
                setErrors(res.error.data ?? { password: res.error.message });
              } else {
                dispatch(setUser({ username: res.username, email: res.email }));
              }
            }}
          >
            <Form className="loginForm">
              <Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Username or Email"
                name="usernameOrEmail"
                type="text"
              />
              <Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                name="password"
              />
              <Button secondary fluid size="large" type="submit">
                Login
              </Button>
            </Form>
          </Formik>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
