/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import axios from "axios";

interface UserStateInterface {
  user: { email: string; username: string } | null;
  profile: any;
}

const initialState: UserStateInterface = {
  user: null,
  profile: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setProfile: (state, { payload }) => {
      state.profile = payload;
    },
  },
});

export const { setUser, setProfile } = userSlice.actions;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchProfile = () => async (dispatch: any): Promise<any> => {
  const { data } = await axios.get("user/profile");
  if (data) dispatch(setProfile(data));
};

export const userSelector = (
  state: RootState
): { email: string; username: string } | null => state.user.user;

export const profileSelector = (state: RootState): any => state.user.profile;

export default userSlice.reducer;
