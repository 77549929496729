import Posts from "components/Posts";

const routes = [
  {
    path: "/",
    name: "Posts",
    title: "Posts Management",
    component: Posts,
  },
];

export default routes;
