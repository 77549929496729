/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

const initialState = {
  cate: null,
};

export const cateSlice = createSlice({
  name: "cate",
  initialState,
  reducers: {
    setCate: (state, { payload }) => {
      state.cate = payload;
    },
  },
});

export const { setCate } = cateSlice.actions;

export const cateSelector = (
  state: RootState
): { text: string; value: string } | null => state.cate.cate;

export default cateSlice.reducer;
