import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Route, useLocation } from "react-router-dom";
import { Header, Menu, Dropdown, Sidebar, Icon } from "semantic-ui-react";
import routes from "../../constant/routes";
import {
  userSelector,
  profileSelector,
  setUser,
  setProfile,
  fetchProfile,
} from "store/userSlice";
import { setCate, cateSelector } from "store/cateSlice";
import { useToken, useCateAdmin } from "hooks";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { logout } from "service/api";

export default function Layout({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const profile = useAppSelector(profileSelector);
  const currentCate = useAppSelector(cateSelector);
  const token = useToken();
  const [visible, setVisible] = React.useState(false);
  const { admin, cate } = useCateAdmin(profile);

  useEffect(() => {
    if (!token) {
      history.replace("/login");
    } else if (!user) {
      dispatch(setUser(token));
    }
  }, [token, user]);

  useEffect(() => {
    if (user && !profile) {
      dispatch(fetchProfile());
    }
  }, [user, profile]);

  useEffect(() => {
    if (!currentCate) {
      const stored = window.localStorage.getItem("cate");
      const storedValue = stored ? JSON.parse(stored) : null;
      if (
        storedValue &&
        (cate || []).some((item) => item.value === storedValue.value)
      ) {
        dispatch(setCate(storedValue));
      } else {
        dispatch(setCate(cate?.[0]));
      }
    }
  }, [currentCate, cate, dispatch]);

  if (!user) return <div />;

  const MenuItem = ({ text, value }: { text: string; value: string }) => {
    return (
      <Menu.Item
        as="a"
        style={{
          color: "black",
          textAlign: "left",
          background: value === currentCate?.value ? "#F8F8F8" : "transparent",
        }}
        onClick={() => {
          window.localStorage.setItem("cate", JSON.stringify({ text, value }));
          dispatch(setCate({ text, value }));
          setVisible(false);
        }}
      >
        {text}
      </Menu.Item>
    );
  };

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <Menu inverted vertical style={{ height: "100%", borderRadius: "0px" }}>
        {admin && (
          <div
            style={{
              padding: "12px 16px",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "22px",
              color: "white",
              marginBottom: "1rem",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              background: visible ? "rgba(225, 225, 225, 0.15)" : "transparent",
            }}
            onClick={() => setVisible(!visible)}
          >
            <div>{currentCate?.text}</div>
            <Icon name="angle right" />
          </div>
        )}
        {routes.map((item, index) => (
          <Menu.Item
            key={index}
            name={item.name}
            active={item.path === location.pathname}
            onClick={() => {
              item.path !== location.pathname && history.push(item.path);
            }}
          />
        ))}
      </Menu>
      <Sidebar.Pushable style={{ flexGrow: 1, display: "flex" }}>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          onHide={() => setVisible(false)}
          vertical
          visible={visible}
          width="thin"
          style={{ background: "white" }}
        >
          {(cate || []).map((item) => (
            <MenuItem text={item.text} value={item.value} key={item.value} />
          ))}
        </Sidebar>
        <Sidebar.Pusher
          style={{ flexGrow: 1, display: "flex" }}
          dimmed={visible}
        >
          <div
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <div
              style={{
                padding: "0.5rem 1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#ffffff",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
              }}
            >
              <Header as="h2" style={{ marginBottom: 0 }}>
                {routes.map((item, index) => (
                  <Route key={index} exact path={item.path}>
                    {item.title}
                  </Route>
                ))}
              </Header>
              <Dropdown text={user?.username ?? user?.email}>
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    text="Logout"
                    onClick={() => {
                      logout();
                      dispatch(setUser(null));
                      dispatch(setProfile(null));
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <section style={{ flexGrow: 1, overflow: "scroll" }}>
              {admin === null ? (
                <div style={{ textAlign: "center" }}>Loading...</div>
              ) : admin === false ? (
                <div style={{ textAlign: "center" }}>Only admin can access</div>
              ) : (
                children
              )}
            </section>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
}
