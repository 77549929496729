import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Login from "./Login";
import Layout from "./Layout";
// import routes from "../constants/routes";
import Posts from "./Posts";

const queryClient = new QueryClient();

export default function App(): JSX.Element {
  const routes = [
    {
      path: "/",
      name: "Posts",
      title: "Posts Management",
      component: <Posts />,
    },
  ];

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path={routes.map((item) => item.path)}>
            <Layout>
              <Switch>
                {routes.map((item, index) => (
                  <Route key={index} exact path={item.path}>
                    {item.component}
                  </Route>
                ))}
              </Switch>
            </Layout>
          </Route>
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
